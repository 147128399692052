import { gql } from "@apollo/client";
import client from "../utils/gqlclient";
import _ from "lodash";

export async function getSingleFolderDetails(transactionId, itemId) {
  const data = await client.query({
    query: getSingleFolderDetailsGql,
    variables: {
      transactionId: transactionId,
      itemId: itemId ? itemId : "",
    },
  });
  return data.data.singleFolderDetails;
}

const getSingleFolderDetailsGql = gql`
  query ($transactionId: String!, $itemId: String!) {
    singleFolderDetails(transactionId: $transactionId, itemId: $itemId) {
      id
      url
      name
      private
      owner
      files {
        id
        url
        name
        thumbnailURL
        status
        owner
      }
      subfolders {
        id
        url
        name
        private
        owner
        files {
          url
          name
          thumbnailURL
          status
          owner
        }
      }
    }
  }
`;

export async function getDocumentDownloadLink(itemId) {
  const data = await client.query({
    query: getFileDownloadLink,
    variables: {
      itemId: itemId,
    },
  });
  return data.data.getFileDownloadLink;
}

const getFileDownloadLink = gql`
  query ($itemId: String!) {
    getFileDownloadLink(itemId: $itemId) {
      downloadLink
    }
  }
`;

export async function getDocumentSearchByTerm({ folderId, itemIds }) {
  const data = await client.query({
    query: getSharepointSearchByTerm,
    variables: {
      folderId,
      itemIds,
    },
  });
  return data.data.sharepointSearchByTerm;
}

const getSharepointSearchByTerm = gql`
  query ($folderId: String!, $itemIds: [String]!) {
    sharepointSearchByTerm(folderId: $folderId, itemIds: $itemIds) {
      files {
        id
        url
        name
        thumbnailURL
        status
      }
    }
  }
`;

export async function getItemDetails({ itemId }) {
  const data = await client.query({
    query: getItemDetailsGql,
    variables: {
      itemId,
    },
  });
  return data.data.getItemDetails;
}

const getItemDetailsGql = gql`
  query ($itemId: String!) {
    getItemDetails(itemId: $itemId) {
      created_by
      day
      month
      year
      item_url
    }
  }
`;

export async function getSearchTerms({ folderId }) {
  const data = await client.query({
    query: getSearchTermsGql,
    variables: {
      folderId,
    },
  });
  return data.data.getSearchTerms || [];
}

const getSearchTermsGql = gql`
  query ($folderId: String!) {
    getSearchTerms(folderId: $folderId) {
      name
      id
    }
  }
`;
