import React, { useRef, useState } from "react";
import styles from "./DocumentFilter.module.scss";
import { Chip, Flex, Row } from "@scrapadev/scrapad-front-sdk";
import { useTranslation } from "../../../../../utils/hooks";
import SortingMenu from "../../../../../components/ui/sorter/SortingMenu";
import { documentActions } from "../../../../../providers/documents/actions/DocumentsActions";
import { useDocumentsProvider } from "../../../../../providers/documents/DocumentsContext";
import SearchBar from "../../../../../components/ui/searchbar/SearchBar";
import BulkActions from "../bulk-actions/BulkActions";
import _ from "lodash";

function DocumentFilter({ hideSearch = false, hideActions = false }) {
  const { t } = useTranslation("common");
  const { state, dispatch, searchFiles, searchByTerm, fetchData } =
    useDocumentsProvider();
  const suggestionsRef = useRef([]);
  const [value, setValue] = useState();

  const search = async (
    term,
    setSuggestions = () => {},
    setOpen = () => {}
  ) => {
    if (_.isEmpty(term)) {
      clearAll(setOpen, setSuggestions);
    } else {
      setValue(term);
      try {
        const results = await searchByTerm(term);
        if (_.isEmpty(results)) {
          setSuggestions({
            search: [],
            prevSearch: [],
          });
        } else {
          const resultsMap = results?.map((e) => ({ term: e?.name, ...e }));
          suggestionsRef.current = resultsMap;
          setSuggestions({
            search: resultsMap,
            prevSearch: [],
          });
        }

        setOpen(true);
      } catch (error) {
        console.log(error);
      }
    }

    // const results = await searchFiles(term);
  };

  const onSubmit = async (
    e,
    open,
    setOpen,
    loading,
    setLoading,
    setSuggestions
  ) => {
    setTimeout(() => {
      setOpen(false);
    }, 25);
    if (!_.isEmpty(e?.target?.item)) {
      if (!_.isEmpty(e?.target?.item?.id)) {
        setValue(e?.target?.item?.name);
        searchFiles([e?.target?.item?.id], e?.target?.item?.name);
        setTimeout(() => {
          setOpen(false);
        }, 50);
      } else {
        await search(e?.target?.item?.term);
        searchFiles(
          suggestionsRef.current?.map((e) => e?.id),
          e?.target?.item?.term
        );
        return;
      }

      return;
    }

    if (_.isEmpty(e?.target?.value)) {
      setTimeout(() => {
        setOpen(false);
      }, 50);
      setSuggestions(undefined);
      return;
    }

    if (typeof e?.target?.value === "string") {
      searchFiles(suggestionsRef.current?.map((e) => e?.id, e?.target?.value));
      // if (suggestionsRef.current?.length === 1) {
      // }
    } else if (typeof e?.target?.value === "object") {
      if (e?.target?.value?.id) {
        searchFiles([e?.target?.value?.id], e?.target?.value?.name);
      } else {
        await search(e?.target?.value?.term);
        searchFiles(
          suggestionsRef.current?.map((e) => e?.id),
          e?.target?.value?.term
        );
        return;
      }
    }

    let prevSug = [];
    try {
      let getPrevSug = window.localStorage.getItem("documents_recent_searchs");
      if (getPrevSug) {
        prevSug = JSON.parse(getPrevSug);
      }
    } catch (error) {
      console.log(error);
    }

    try {
      let newPrevSug = [];
      if (_.isEmpty(prevSug)) {
        newPrevSug = [
          typeof e?.target?.value === "string"
            ? {
                term: e?.target?.value,
                name: e?.target?.value,
              }
            : e?.target?.value,
        ];
      } else {
        newPrevSug = [
          typeof e?.target?.value === "string"
            ? {
                term: e?.target?.value,
                name: e?.target?.value,
              }
            : e?.target?.value,
          ...prevSug,
        ];
      }

      if (newPrevSug.length > 8) {
        newPrevSug.splice(7, newPrevSug.length - 8);
      }

      window.localStorage.setItem(
        "documents_recent_searchs",
        JSON.stringify(newPrevSug)
      );
    } catch (error) {
      console.log(error);
    }
  };

  const clearAll = (setOpen, setSuggestions) => {
    setValue("");
    setTimeout(() => {
      setOpen(false);
      setSuggestions({
        search: [],
        prevSearch: [],
      });
    }, 50);

    fetchData(state?.transactionUuid, undefined, false, true);
  };

  const loadRecentSuggestions = (suggestions, setSuggestions) => {
    const recent_searchs = JSON.parse(
      window.localStorage.getItem("documents_recent_searchs") || "[]"
    );
    setSuggestions({
      search: value?.length > 0 ? suggestions?.search : [],
      prevSearch: recent_searchs,
    });
  };

  return (
    <div
      className={`${styles["document_filter"]} ${
        hideSearch ? styles["hideSearch"] : ""
      } ${hideActions ? styles["hideActions"] : ""}`}
    >
      {!hideSearch && (
        <Flex flexDirection="column" alignItems="flex-start">
          <SearchBar
            value={value}
            hideSuggestionOnEmpty={true}
            hideSearchButton={true}
            placeholder={t("search")}
            handleOnChange={(
              e,
              open,
              setOpen,
              loading,
              setLoading,
              setSuggestions
            ) => {
              search(e.target.value, setSuggestions, setOpen);
            }}
            handleOnSubmit={(e, open, setOpen, loading, setLoading) => {
              onSubmit(e, open, setOpen, loading, setLoading);
            }}
            onClearSearch={(setOpen, setSuggestions) => {
              clearAll(setOpen, setSuggestions);
            }}
            onClick={(open, suggestions, setSuggestions) => {
              if (open) {
                loadRecentSuggestions(suggestions, setSuggestions);
              }
            }}
          />
        </Flex>
      )}
      {!hideActions && (
        <div className={styles["actions"]}>
          <Row gap={15} align="center" justify="space-between">
            <Row gap={10} wrap>
              {/* <ChipDropdown
              type="white-outlined"
              text={"Tipo de documento"}
              customRenderItem={(item, openDropdown, setOpenDropdown) => {
                return (
                  <ClearButton onClick={(e) => {}}>
                    {item.component}
                  </ClearButton>
                );
              }}
              items={[]}
            /> */}
              <Chip
                type="white-outlined"
                text={t("new")}
                icon="add"
                showIcon
                handleClick={() => {
                  dispatch({
                    type: documentActions.UPLOAD_FILE_OPEN,
                  });
                }}
              />
            </Row>
            <Row align="center" justify="flex-end">
              <BulkActions />
              <SortingMenu
                onChange={(e) => {
                  dispatch({
                    type: documentActions.SORTING_SET,
                    payload: e,
                  });
                }}
              />
            </Row>
          </Row>
        </div>
      )}
    </div>
  );
}

export default DocumentFilter;
