import {
  BREAKPOINT_LG,
  Flex,
  Paper,
  Row,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import React from "react";
import DocumentManagerBreadcrumbs from "./ui/breadcrumbs/DocumentManagerBreadcrumbs";
import DocumentFilter from "./ui/documentsFilter/DocumentFilter";
import FileList from "./ui/file_list/FileList";
import {
  DocumentsProvider,
  useDocumentsProvider,
} from "../../../providers/documents/DocumentsContext";
import DocumentDetailPanel from "./ui/detail-panel/DocumentDetailPanel";

function DocumentManager({ transactionUuid, data = [] }) {
  return (
    <DocumentsProvider transactionUuid={transactionUuid} data={data}>
      <DocumentManagerInner />
    </DocumentsProvider>
  );
}

function DocumentManagerInner() {
  const { state } = useDocumentsProvider();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);

  return (
    <Paper>
      <Flex
        flexDirection="column"
        alignItems="stretch"
        style={{ width: "100%", gap: "15px" }}
      >
        <Flex
          flexDirection="column"
          alignItems="stretch"
          style={{ gap: "5px" }}
        >
          <Row wrap={matches} gap={15} align="center" justify="space-between">
            <div style={{ width: "100%" }}>
              <DocumentManagerBreadcrumbs />
            </div>
            <DocumentFilter hideActions hideSearch />
          </Row>
          <DocumentFilter hideSearch />
        </Flex>
        <Row gap={10}>
          <Paper whiteMode style={{ padding: "15px", alignItems: "stretch" }}>
            <FileList />
          </Paper>
          {state?.detailPanelUuid && (
            <Paper whiteMode style={{ maxWidth: "300px", width: "100%" }}>
              <DocumentDetailPanel uuid={state?.detailPanelUuid} />
            </Paper>
          )}
        </Row>
      </Flex>
    </Paper>
  );
}

export default DocumentManager;
