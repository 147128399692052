import React, { useState } from "react";
import WidgetDataWrapper from "../../wrappers/DataWrapper";
import { getBusinessModel } from "../../../model/business";
import { useTranslation } from "../../../utils/hooks";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";
import {
  Alert,
  BREAKPOINT_MD,
  Button,
  DropDown,
  Heading,
  InputEmail,
  InputPhoneWithPrefix,
  InputText,
  Label,
  Paper,
  Radio,
  Row,
  Spacer,
  Typography,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import {
  countryCodesProps,
  handleCountryClickDeep,
  handleItemSelectedDeep,
  handleOnChange,
  handleOnChangeDeep,
  handleOnChangeDeepWithoutMergeData,
  handlePrefixOnChangeDeep,
  phoneTranslator,
} from "../../../utils/functions";
import InputCountries from "../../../components/ui/input/country/Country";
import InputActivity from "../../../components/ui/input/activity/Activity";
import { handleCRUD } from "../../../utils/crud/functions";
import {
  LEGAL_FORMS,
  LOAD_POSSIBILITY,
  modalTypes,
} from "../../../utils/variables";
import deepmerge from "deepmerge";
import CatalogSearch from "../../../components/ui/input/search/CatalogSearch";
import _ from "lodash";
import { getCheckNifExist } from "../../../model/opportunities";
import ModalFactory from "../../../components/ui/modal/ModalFactory";
import LoadPossibilities from "../../../components/ui/load-possibilities/LoadPossibilities";
import ValidateGroup from "../../../components/ui/input/validation/ValidateGroup";

function BusinessRegister({ onSuccess = () => {} }) {
  const handleUpdateStateCallback = (state, childIsDirty) => {};

  return (
    <WidgetDataWrapper
      id={""}
      fnData={getBusinessModel}
      Component={BusinessRegisterForm}
      fnParameters={{}}
      extraData={{}}
      updateStateCallback={handleUpdateStateCallback}
      parentCallback={(e) => {}}
      onSuccess={onSuccess}
    />
  );
}

function BusinessRegisterForm({
  id,
  state,
  dispatch,
  actions,
  isDirty,
  handleDiscard,
  handleParentCallback,
  fetchData,
  extraData,
  onSuccess = () => {},
}) {
  const { t, i18n } = useTranslation(["common", "transactions", "business"]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_MD}px)`);
  const [nifExist, setNifExist] = useState(false);
  const [isPort, setIsPort] = useState(false);

  const handleSubmitWidget = async (e) => {
    e.preventDefault();
    handleParentCallback();
    setSubmitLoading(true);
    const data = deepmerge(state, {
      fiscalAddress: {
        street: _.isEmpty(state?.fiscalAddress?.port)
          ? `${state?.fiscalAddress?.street} ${state?.fiscalAddress?.streetNumber}`
          : "",
      },
    });
    delete data?.fiscalAddress?.streetNumber;
    delete data?.basicData?.countryName;

    if (_.isEmpty(data?.fiscalAddress?.port)) {
      delete data?.fiscalAddress?.port;
    }
    await handleCRUD({
      endpoint: `org?step=org-data-basic`,
      method: "POST",
      data: data,
      successCallback: (e) => {
        onSuccess({ uuid: e, name: state?.basicData?.commercialName });
      },
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
      setLoading: setSubmitLoading,
    });
  };

  const handleNifAndCountry = (key, parent) => (option, open, setOpen) => {
    const countryData = option.uuid.split("-");
    const countryId = countryData[0];
    const countryName = countryData[1];
    const obj = {};
    obj[parent] = {};
    obj[parent][key] = parseInt(countryId);
    obj[parent]["countryName"] = countryName;
    // const newNif = state[parent].fiscalId?.replace(/^.{2}/g, countryName);
    // obj[parent][fiscalKey] = newNif;
    dispatch({ type: actions.UPDATE_INPUT_MERGED, payload: obj });
    setTimeout(() => {
      setOpen(false);
    }, 100);
  };

  const checkIfFiscalIdExist = async (value) => {
    const response = await getCheckNifExist({ nif: value });
    if (!_.isEmpty(response)) {
      setNifExist(true);
      handleOnChangeDeep(
        "fiscalId",
        "basicData",
        dispatch
      )({
        target: {
          value: "",
        },
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmitWidget}>
        <Paper
          whiteMode
          style={{ alignItems: "stretch", padding: "20px 25px" }}
        >
          <Heading type={4}>{t("business-info")}</Heading>
          <Row gap={30} wrap={matches}>
            <Label text={t("official-name")} noPadding>
              <InputText
                required
                value={state?.basicData?.orgName}
                onChange={handleOnChangeDeep("orgName", "basicData", dispatch)}
                placeholder={t("write-here")}
                mode="underline"
              />
            </Label>
            <Label text={t("comercial-name")} noPadding>
              <InputText
                required
                value={state?.basicData?.commercialName}
                onChange={handleOnChangeDeep(
                  "commercialName",
                  "basicData",
                  dispatch
                )}
                placeholder={t("write-here")}
                mode="underline"
              />
            </Label>
          </Row>
          <Row gap={30} wrap={matches}>
            <Label text={t("legal-form")} noPadding>
              <DropDown
                required
                placeholder={t("select-value")}
                options={Object.keys(LEGAL_FORMS).map((lf) => ({
                  uuid: LEGAL_FORMS[lf],
                  text: t(LEGAL_FORMS[lf]),
                }))}
                value={t(
                  Object.keys(LEGAL_FORMS).find(
                    (f) => f === state?.basicData?.legalForm
                  )
                )}
                handleItemClick={handleItemSelectedDeep(
                  "legalForm",
                  "basicData",
                  dispatch
                )}
                disableEnterText={false}
                position="fixed"
                mode="underline"
              />
            </Label>
            <Label text={t("country")} noPadding>
              <InputCountries
                handleItemClick={handleNifAndCountry(
                  "countryCode",
                  "basicData"
                )}
                value={state?.basicData?.countryCode}
                required
                autoComplete={"off"}
                mode="underline"
              />
            </Label>
          </Row>
          <Row gap={30} wrap={matches}>
            <Label text={t("nif-number")} noPadding>
              <div style={{ position: "relative" }}>
                {state?.basicData?.countryName && (
                  <Typography
                    style={{
                      position: "absolute",
                      left: "15px",
                      top: "12px",
                      zIndex: 5,
                    }}
                  >
                    {state?.basicData?.countryName}
                  </Typography>
                )}
                <InputText
                  required
                  value={state?.basicData?.fiscalId}
                  onChange={handleOnChangeDeep(
                    "fiscalId",
                    "basicData",
                    dispatch
                  )}
                  onBlur={(e) => {
                    checkIfFiscalIdExist(
                      `${state?.basicData?.countryName}${e.target.value}`
                    );
                  }}
                  placeholder={t("write-here")}
                  style={{
                    paddingLeft: state?.basicData?.countryName
                      ? "40px"
                      : "auto",
                  }}
                  disabled={!state?.basicData?.countryCode}
                  mode="underline"
                />
              </div>
            </Label>
            <Label text={t("phone")} noPadding>
              <InputPhoneWithPrefix
                required
                prefixValue={state?.basicData?.prefix}
                prefixOnchange={handlePrefixOnChangeDeep(
                  "prefix",
                  "basicData",
                  dispatch
                )}
                phoneValue={state?.basicData?.phone}
                phoneOnChange={handleOnChangeDeep(
                  "phone",
                  "basicData",
                  dispatch
                )}
                codesProps={countryCodesProps(
                  state?.prefix,
                  t,
                  i18n,
                  true,
                  "aboslute"
                )}
                phoneProps={{ skipRequiredHelp: true }}
                labelProps={{ light: true }}
                labelInput={t("phone")}
                prefixLabel={state?.basicData?.prefix}
                translation={phoneTranslator(t)}
                mode="underline"
              />
            </Label>
          </Row>
          <Row gap={30} wrap={matches}>
            <Label text={t("business-activity")} noPadding>
              <InputActivity
                required
                handleItemClick={handleItemSelectedDeep(
                  "activity",
                  "basicData",
                  dispatch
                )}
                value={state?.basicData?.activity}
                skipRequiredHelp
                mode="underline"
              />
            </Label>
            <Label
              text={`${t("website")} (${t("optional").toLowerCase()})`}
              noPadding
            >
              <InputText
                value={state?.basicData?.webSite}
                onChange={handleOnChangeDeep("webSite", "basicData", dispatch)}
                placeholder={t("write-here")}
                mode="underline"
              />
            </Label>
          </Row>
        </Paper>
        <Spacer height={15} />
        <Paper
          whiteMode
          style={{ alignItems: "stretch", padding: "20px 25px", gap: 15 }}
        >
          <Heading type={4} style={{ marginBottom: 0 }}>
            {t("address")}
          </Heading>
          <Row gap={25}>
            <span>
              <Radio
                id="address_yes"
                name="isPort"
                text={t("address")}
                onChange={() => {
                  setIsPort(false);
                  handleOnChangeDeep(
                    "port",
                    "fiscalAddress",
                    dispatch
                  )({
                    target: {
                      value: "",
                    },
                  });
                }}
                checked={isPort === false}
                required
              />
            </span>
            <span>
              <Radio
                id="address_no"
                name="isPort"
                text={t("port")}
                onChange={() => {
                  setIsPort(true);
                  handleOnChange(
                    "fiscalAddress",
                    dispatch
                  )({
                    target: {
                      value: {
                        city: "",
                        countryCode: undefined,
                        facilities: [],
                        postalCode: "",
                        state: "",
                        street: "",
                      },
                    },
                  });
                }}
                checked={isPort === true}
                required
              />
            </span>
          </Row>
          {isPort && (
            <Label text={`${t("port")}`} noPadding>
              <CatalogSearch
                remote
                id="SelectedPort"
                inputProps={{
                  required: true,
                  placeholder: t("write-or-select"),
                  mode: "underline",
                }}
                initialValue={
                  state?.selectedAddress?.port &&
                  state?.selectedAddress?.port !== "empty"
                    ? state?.selectedAddress?.port?.name ||
                      state?.selectedAddress?.port
                    : undefined
                }
                catalogName={"ports"}
                avoidInitalLoad={true}
                onClickCallback={(option, open, setOpen, value, setValue) => {
                  handleOnChangeDeep(
                    "port",
                    "fiscalAddress",
                    dispatch
                  )({
                    target: {
                      value: option?.uuid,
                    },
                  });
                  setTimeout(() => {
                    setOpen(false);
                  }, 100);
                }}
                onChangeCallback={(e) => {
                  if (_.isEmpty(e.target.value)) {
                    handleOnChangeDeep(
                      "port",
                      "fiscalAddress",
                      dispatch
                    )({
                      target: {
                        value: undefined,
                      },
                    });
                  }
                }}
                mode="underline"
              />
            </Label>
          )}
          {!isPort && (
            <>
              <Row gap={30} wrap={matches}>
                <Label text={t("street")} noPadding>
                  <InputText
                    required
                    value={state?.fiscalAddress?.street}
                    onChange={handleOnChangeDeep(
                      "street",
                      "fiscalAddress",
                      dispatch
                    )}
                    placeholder={t("write-here")}
                    mode="underline"
                  />
                </Label>
                <Row gap={30} wrap={matches} style={{ width: "100%" }}>
                  <Label text={t("number")} noPadding>
                    <InputText
                      required
                      value={state?.fiscalAddress?.streetNumber}
                      onChange={handleOnChangeDeep(
                        "streetNumber",
                        "fiscalAddress",
                        dispatch
                      )}
                      placeholder={t("write-here")}
                      mode="underline"
                    />
                  </Label>
                  <Label text={t("postal-code")} noPadding>
                    <InputText
                      required
                      value={state?.fiscalAddress?.postalCode}
                      onChange={handleOnChangeDeep(
                        "postalCode",
                        "fiscalAddress",
                        dispatch
                      )}
                      placeholder={t("write-here")}
                      mode="underline"
                    />
                  </Label>
                </Row>
              </Row>
              <Row gap={30} wrap={matches}>
                <Label text={t("city")} noPadding>
                  <InputText
                    required
                    value={state?.fiscalAddress?.city}
                    onChange={handleOnChangeDeep(
                      "city",
                      "fiscalAddress",
                      dispatch
                    )}
                    placeholder={t("write-here")}
                    mode="underline"
                  />
                </Label>
                <Label text={t("state")} noPadding>
                  <InputText
                    required
                    value={state?.fiscalAddress?.state}
                    onChange={handleOnChangeDeep(
                      "state",
                      "fiscalAddress",
                      dispatch
                    )}
                    placeholder={t("write-here")}
                    mode="underline"
                  />
                </Label>
              </Row>
              <Row>
                <Label text={t("country")} noPadding>
                  <InputCountries
                    required={true}
                    handleItemClick={handleCountryClickDeep(
                      "countryCode",
                      "fiscalAddress",
                      dispatch
                    )}
                    value={state?.fiscalAddress?.countryCode}
                    mode="underline"
                  />
                </Label>
              </Row>
              <LoadPossibilities
                title={t(
                  state?.opportunityType === "sale"
                    ? "load_possibilities"
                    : "unload_possibilities"
                )}
                handleClick={(choice) => {
                  if (choice === LOAD_POSSIBILITY.none) {
                    handleOnChangeDeepWithoutMergeData(
                      "facilities",
                      "fiscalAddress",
                      dispatch,
                      state?.fiscalAddress
                    )({
                      target: {
                        value: [choice],
                      },
                    });
                    return;
                  }
                  const facilities = [...state?.fiscalAddress?.facilities];
                  const choiceFound = facilities?.find((f) => f === choice);
                  handleOnChangeDeepWithoutMergeData(
                    "facilities",
                    "fiscalAddress",
                    dispatch,
                    state?.fiscalAddress
                  )({
                    target: {
                      value: choiceFound
                        ? facilities.filter((f) => f !== choice)
                        : [...facilities, choice],
                    },
                  });
                }}
                showIconFn={(type) =>
                  state?.fiscalAddress?.facilities?.includes(type)
                }
                typeFn={(type) =>
                  state?.fiscalAddress?.facilities?.includes(type)
                }
              />
              <ValidateGroup
                value={
                  state?.fiscalAddress?.facilities?.length > 0 ? 1 : undefined
                }
              />
            </>
          )}
        </Paper>
        <Spacer height={15} />
        <Paper
          whiteMode
          style={{ alignItems: "stretch", padding: "20px 25px" }}
        >
          <Heading type={4}>{t("contact_person")}</Heading>
          <Row gap={30} wrap={matches}>
            <Label text={t("name")} noPadding>
              <InputText
                required
                value={state?.legalReprenstative?.firstName}
                onChange={handleOnChangeDeep(
                  "firstName",
                  "legalReprenstative",
                  dispatch
                )}
                placeholder={t("write-here")}
                mode="underline"
              />
            </Label>
            <Label text={t("surname")} noPadding>
              <InputText
                required
                value={state?.legalReprenstative?.lastName}
                onChange={handleOnChangeDeep(
                  "lastName",
                  "legalReprenstative",
                  dispatch
                )}
                placeholder={t("write-here")}
                mode="underline"
              />
            </Label>
          </Row>
          <Row gap={30} wrap={matches}>
            <Label text={t("email")} noPadding>
              <InputEmail
                required
                value={state?.legalReprenstative?.email}
                onChange={handleOnChangeDeep(
                  "email",
                  "legalReprenstative",
                  dispatch
                )}
                placeholder={t("write-here")}
                mode="underline"
              />
            </Label>
            <Label text={t("dni-number")} noPadding>
              <InputText
                required
                value={state?.legalReprenstative?.identityCard}
                onChange={handleOnChangeDeep(
                  "identityCard",
                  "legalReprenstative",
                  dispatch
                )}
                placeholder={t("write-here")}
                mode="underline"
              />
            </Label>
          </Row>
          <Row gap={30} wrap={matches}>
            <Label text={t("phone")} noPadding>
              <InputPhoneWithPrefix
                required
                prefixValue={state?.legalReprenstative?.prefix}
                prefixOnchange={handlePrefixOnChangeDeep(
                  "prefix",
                  "legalReprenstative",
                  dispatch
                )}
                phoneValue={state?.legalReprenstative?.phone}
                phoneOnChange={handleOnChangeDeep(
                  "phone",
                  "legalReprenstative",
                  dispatch
                )}
                codesProps={countryCodesProps(
                  state?.legalReprenstative?.prefix,
                  t,
                  i18n,
                  true,
                  "aboslute"
                )}
                phoneProps={{ skipRequiredHelp: true }}
                labelProps={{ light: true }}
                labelInput={t("phone")}
                prefixLabel={state?.legalReprenstative?.prefix}
                translation={phoneTranslator(t)}
                mode="underline"
              />
            </Label>
          </Row>
        </Paper>
        <Spacer height={15} />
        <Row justify="flex-end">
          <Button
            fullwidth={matches ? true : undefined}
            component="button"
            mode="info"
            text={t("save")}
            loading={submitLoading}
            style={
              submitLoading ? { paddingLeft: "4rem", paddingRight: "4rem" } : {}
            }
          />
        </Row>
      </form>
      <ModalFactory
        open={nifExist}
        type={modalTypes.NIF_ALREADY_EXISTS}
        setOpen={setNifExist}
      />
    </>
  );
}

export default BusinessRegister;
