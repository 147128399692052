import {
  Container,
  Divider,
  Flex,
  Heading,
  Icon,
  ImageBox,
  Loading,
  Paper,
  Row,
  Tracking,
  Typography,
  WARM_NEUTRAL_60,
  toastError,
} from "@scrapadev/scrapad-front-sdk";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import NavBar from "../../components/ui/navbar/NavBar";
import LayoutDetail from "../../layout/detail/Detail";
import { useTranslation } from "../../utils/hooks";
import TransactionSteps from "../../widgets/transactions/steps/Steps";
import { useEffect, useState } from "react";
import { gqlWrapper } from "../../utils/gqlwrapper";
import { getTransactionDataBO } from "../../model/transactions";
import { useSetDataUser, useSignOut } from "../../providers/AuthHooks";
import TransactionHeader from "./header/TransactionHeader";
import TransactionDetailHeader from "./header/Detail";
import { useOperationsProvider } from "../../providers/organization/OperationsContext";
import { useDocumentTitle } from "../../utils/document/hooks";
import {
  getUuidPartsFromTransportData,
  trackingTransformer,
} from "../../utils/functions";
import MaterialText from "../../components/ui/material-text/MaterialText";
import ItemText from "../../components/ui/item/ItemText";
import PaperSectionHeader from "../../components/ui/paper-section-header/PaperSectionHeader";
import UnderlinedLink from "../../components/ui/link/UnderlinedLink";
import ModalFactory from "../../components/ui/modal/ModalFactory";
import { modalTypes } from "../../utils/variables";
import { DataProvider } from "../../providers/data/DataContext";
import { getAd } from "../../model/ads";
import _ from "lodash";
import ChipOutlined from "../../components/ui/chip/ChipOutlined";
// import QuotationBlock from "./quotation/QuotationBlock";

const TransactionTransport = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const fromWhere = searchParams.get("from");

  const { t, i18n, values } = useTranslation([
    "common",
    "transactions",
    "finances",
    "ads",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const signOut = useSignOut();
  const navigate = useNavigate();
  // const matches = useMatchMedia(`(max-width: ${BREAKPOINT_LG}px)`);
  // const user = useUser();
  const setDataUser = useSetDataUser();
  const [data, setData] = useState([]);
  const [adData, setAdData] = useState({});
  // const [addQuotationOpen, setAddQuotationOpen] = useState(false);
  const [editRouteOpen, setEditRouteOpen] = useState(false);
  const [editLoadCharacteristics, setEditLoadCharacteristics] = useState(false);
  const { canManageTransaction, canReadLogistics } = useOperationsProvider();

  useEffect(() => {
    fetchData();
  }, [i18n.language]);

  const fetchData = async (quiet = false) => {
    if (!quiet) setIsLoading(true);
    try {
      // const fn = await gqlWrapper(getTransactionDetail, setDataUser, signOut);
      const fn = await gqlWrapper(getTransactionDataBO, setDataUser, signOut);
      const results = await fn(i18n.language, id);
      if (results === null) {
        setData([]);
      } else {
        if (results?.errors?.length > 0) {
          setData([]);
          toastError(results?.errors[0]?.message);
        } else {
          setData(results);
          fetchAdData(results?.sideData?.originAd?.uuidAd);
        }
      }
    } catch (e) {
      setData([]);
      toastError(e.message || e);
    } finally {
      if (!quiet) setIsLoading(false);
    }
  };

  const fetchAdData = async (uuid, quiet = true) => {
    if (!uuid) {
      return;
    }
    if (!quiet) setIsLoading(true);
    try {
      const fn = await gqlWrapper(getAd, setDataUser, signOut);
      const results = await fn(i18n.language, uuid);
      if (results === null) {
        setAdData({});
      } else {
        if (results?.errors?.length > 0) {
          setAdData({});
          toastError(results?.errors[0]?.message);
        } else {
          setAdData(results);
        }
      }
    } catch (e) {
      setAdData({});
      toastError(e.message || e);
    } finally {
      if (!quiet) setIsLoading(false);
    }
  };

  let typeIcon = (
    <Icon
      name={"swap_horiz"}
      color={WARM_NEUTRAL_60}
      style={{
        fontSize: "16px",
      }}
    />
  );
  const subtitle = (
    <ChipOutlined iconComponent={typeIcon}>
      <span style={{ textTransform: "uppercase" }}>
        {data?.header?.uuidConversation?.split("-")?.[0]}
      </span>
    </ChipOutlined>
  );
  useDocumentTitle(`${t("transaction")} ${id.split("-")?.[0]}`, values);

  const { uuidBuyer, uuidSeller } = getUuidPartsFromTransportData(
    data?.sideData
  );

  return (
    <>
      <NavBar />
      <LayoutDetail
        backgroundColor="#fff"
        headerWithContainer
        headerComponent={
          <Container>
            <div className="row">
              <div className="col-12">
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    <TransactionHeader
                      goBack
                      imageUrl={data?.sideData?.originAd?.image}
                      breadcrumbs={
                        fromWhere
                          ? [
                              {
                                description: t(fromWhere),
                                onClick: () =>
                                  navigate(-1, {
                                    replace: true,
                                  }),
                              },
                              { description: data?.header?.adTitle },
                            ]
                          : [
                              {
                                description: t("transactions"),
                                onClick: () =>
                                  navigate("/transactions", {
                                    replace: true,
                                  }),
                              },
                              { description: data?.header?.adTitle },
                            ]
                      }
                      title={data?.header?.adTitle}
                      subtitle={subtitle}
                      rightArea={
                        <TransactionDetailHeader
                          uuidEvent={id}
                          title={data?.header?.adTitle}
                          subtitle={subtitle}
                          fetchData={fetchData}
                          permission={canManageTransaction}
                          permissionLogistics={canReadLogistics}
                          onlyMenu
                        />
                      }
                    />
                  </>
                )}
              </div>
            </div>
          </Container>
        }
        bodyComponent={
          <>
            <Container style={{ marginBottom: "10px" }}>
              <TransactionSteps id={id} currentStep={"transport"} />
            </Container>
            <Container>
              <Flex
                flexDirection="column"
                alignItems="stretch"
                style={{ gap: "10px" }}
              >
                <Paper style={{ display: "block" }}>
                  <Flex
                    flexDirection="column"
                    alignItems="stretch"
                    style={{ gap: "10px" }}
                  >
                    {/* {canReadLogistics() &&
                    JSON.parse(process.env.REACT_APP_TRANSPORT_QUOTATION) ? (
                      <Paper whiteMode style={{ alignItems: "stretch" }}>
                        <QuotationBlock
                          uuidBuyer={uuidBuyer}
                          uuidSeller={uuidSeller}
                          id={id}
                        />
                      </Paper>
                    ) : (
                      <></>
                    )} */}

                    <Paper whiteMode style={{ alignItems: "stretch" }}>
                      <Flex
                        flexDirection="column"
                        alignItems="stretch"
                        style={{ gap: "10px" }}
                      >
                        <PaperSectionHeader
                          left={
                            <Heading type={6} style={{ margin: 0 }}>
                              {t("route")}
                            </Heading>
                          }
                          // right={
                          //   <UnderlinedLink
                          //     onClick={() => {
                          //       setEditRouteOpen(true);
                          //     }}
                          //   >
                          //     {t("edit")}
                          //   </UnderlinedLink>
                          // }
                        />

                        <Tracking
                          breakpoint={7000}
                          steps={trackingTransformer(
                            data?.sideData?.transportData,
                            i18n,
                            data?.sideData?.originAd?.type?.toLowerCase()
                          )}
                        />
                      </Flex>
                    </Paper>
                    <Paper whiteMode style={{ alignItems: "stretch" }}>
                      <PaperSectionHeader
                        left={
                          <Heading type={6} style={{ margin: 0 }}>
                            {t("material")}
                          </Heading>
                        }
                        right={
                          <UnderlinedLink
                            alternative={true}
                            onClick={() => {
                              console.log();
                              window?.open(
                                `/ads/${data?.sideData?.originAd?.uuidAd}`,
                                "_blank"
                              );
                            }}
                          >
                            {t("view-ad")}
                          </UnderlinedLink>
                        }
                      />
                      <Typography style={{ margin: 0 }}>
                        <MaterialText
                          text={data?.sideData?.originAd?.material}
                        />
                      </Typography>
                      <Row gap={5} wrap>
                        {adData?.images?.map((image) => (
                          <ImageBox
                            url={image}
                            style={{ width: "80px", height: "80px" }}
                            innerStyle={{ width: "80px", height: "80px" }}
                          />
                        ))}
                      </Row>
                      <Divider style={{ margin: "10px 0" }} />
                      <PaperSectionHeader
                        left={
                          <Heading type={7} style={{ margin: 0 }}>
                            {t("ad-load-carateristics")}
                          </Heading>
                        }
                        // right={
                        //   <UnderlinedLink
                        //     onClick={() => {
                        //       setEditLoadCharacteristics(true);
                        //     }}
                        //   >
                        //     {t("edit")}
                        //   </UnderlinedLink>
                        // }
                      />

                      <Flex
                        flexDirection="column"
                        alignItems="flex-start"
                        style={{ gap: "10px" }}
                      >
                        {!_.isEmpty(adData?.lerCodes) && (
                          <ItemText
                            label={t("ler-code")}
                            value={adData?.lerCodes
                              ?.map((code) => code.name)
                              ?.join(", ")}
                          />
                        )}
                        {adData?.loadCharacteristics?.map((lc) => {
                          if (lc.type === "granel") {
                            return (
                              <ItemText
                                label={t("format")}
                                value={t(lc.type)}
                              />
                            );
                          } else {
                            return (
                              <>
                                <ItemText
                                  label={t("format")}
                                  value={t(lc.type)}
                                />
                                <ItemText
                                  label={t("package_load")}
                                  value={lc?.value.weight}
                                />
                                <ItemText
                                  label={`${t("package_dimensions")} (cm)`}
                                  value={`${lc?.value?.height} x ${lc?.value?.width} x ${lc?.value?.depth}`}
                                />
                              </>
                            );
                          }
                        })}
                      </Flex>
                    </Paper>
                  </Flex>
                </Paper>
              </Flex>
            </Container>
            {/* {addQuotationOpen && (
              <DataProvider>
                <ModalFactory
                  key={""}
                  uuidQuote={""}
                  uuidBuyer={uuidBuyer}
                  uuidSeller={uuidSeller}
                  type={modalTypes.LOGISTICS_ADD_QUOTATION}
                  open={addQuotationOpen}
                  setOpen={setAddQuotationOpen}
                  isEdit={false}
                />
              </DataProvider>
            )} */}
            {editRouteOpen && (
              <DataProvider>
                <ModalFactory
                  key={""}
                  uuidQuote={""}
                  uuidBuyer={uuidBuyer}
                  uuidSeller={uuidSeller}
                  type={modalTypes.LOGISTICS_EDIT_ROUTE}
                  open={editRouteOpen}
                  setOpen={setEditRouteOpen}
                />
              </DataProvider>
            )}
            {editLoadCharacteristics && (
              <DataProvider>
                <ModalFactory
                  key={""}
                  uuidQuote={""}
                  type={modalTypes.LOGISTICS_EDIT_LOAD_CHARACTERISTICS}
                  open={editLoadCharacteristics}
                  setOpen={setEditLoadCharacteristics}
                />
              </DataProvider>
            )}
          </>
        }
      />
    </>
  );
};

export default TransactionTransport;
