import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "../../utils/hooks";
import { useSetDataUser, useSignOut } from "../../providers/AuthHooks";
import { gqlWrapper } from "../../utils/gqlwrapper";
import { getFavAddresses } from "../../model/business";
import { Dropdown, Spinner } from "@scrapadev/scrapad-front-sdk";
import { printAddress } from "../../utils/functions";

function AddressSelector({
  uuid,
  uuidOrg,
  savedDirectionProps = {},
  onFetchCallback,
}) {
  const [favDirections, setFavDirections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { i18n } = useTranslation();
  const setDataUser = useSetDataUser();
  const signOut = useSignOut();
  const addressIDRef = useRef(uuid);

  useEffect(() => {
    if (uuid) {
      addressIDRef.current = uuid;
    }
  }, [uuid]);

  useEffect(() => {
    if (uuidOrg) fetchData();
  }, [uuidOrg]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      //const countriesData = await getCountries(i18n.language);
      //setCountries(countriesData);
      const fn = await gqlWrapper(getFavAddresses, setDataUser, signOut);
      const response = await fn({
        locale: i18n.language,
        module: "locations",
        org: uuidOrg,
      });

      const res = response.getOrgLocations;
      let parseAddress = res.map((loc) => ({
        uuid: loc.id,
        place: loc.alias,
        type: loc.type,
        city: loc.city,
        state: loc.state,
        street: loc.street,
        port: loc.port,
        country: loc.country,
        countryCode: loc.countryCode,
        facilities: loc.facilities,
      }));
      setFavDirections(parseAddress);
      if (typeof onFetchCallback === "function") {
        onFetchCallback(parseAddress);
      }
    } catch (err) {
      console.log(err);
      setFavDirections([]);
      if (typeof onFetchCallback === "function") {
        onFetchCallback([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const favAddressData = favDirections?.find(
    (f) => f.uuid === savedDirectionProps?.val
  );

  const getAddressName = (address = {}) => printAddress(address);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <Dropdown
            name="favourite_address"
            required={true}
            options={favDirections.map((fd) => ({
              uuid: fd.uuid,
              text: getAddressName(fd),
              type: fd.type,
            }))}
            value={favAddressData ? getAddressName(favAddressData) : ""}
            handleItemClick={(option, open, setOpen) => {
              if (savedDirectionProps.onChange)
                savedDirectionProps.onChange(option, open, setOpen);
            }}
            {...savedDirectionProps}
            autoComplete="off"
            mode="underline"
          />
        </>
      )}
    </>
  );
}

export default AddressSelector;
