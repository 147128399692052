export async function getAddressModel() {
  return {
    city: "",
    street: "",
    streetNumber: "",
    postalCode: "",
    countryCode: "",
    port: "",
    facilities: [],
  };
}
