import React, { useState } from "react";
import WidgetDataWrapper from "../../wrappers/DataWrapper";
import { useTranslation } from "../../../utils/hooks";
import { useSetDataUser, useSignOut } from "../../../providers/AuthHooks";
import {
  BREAKPOINT_MD,
  Button,
  InputText,
  Label,
  Paper,
  Radio,
  Row,
  Spacer,
  useMatchMedia,
} from "@scrapadev/scrapad-front-sdk";
import { handleCountryClick, handleOnChange } from "../../../utils/functions";
import { getAddressModel } from "../../../model/address";
import InputCountries from "../../../components/ui/input/country/Country";
import CatalogSearch from "../../../components/ui/input/search/CatalogSearch";
import _ from "lodash";
import { handleCRUD } from "../../../utils/crud/functions";
import { buildNewOpportunityAddress } from "../../../utils/opportunities/functions";
import LoadPossibilities from "../../../components/ui/load-possibilities/LoadPossibilities";
import { LOAD_POSSIBILITY } from "../../../utils/variables";
import ValidateGroup from "../../../components/ui/input/validation/ValidateGroup";

function NewAddressForm({ orgUuid, onSuccess = () => {} }) {
  const handleUpdateStateCallback = (state, childIsDirty) => {};

  return (
    <WidgetDataWrapper
      id={""}
      fnData={getAddressModel}
      Component={NewAddressFormWidget}
      fnParameters={{}}
      extraData={{}}
      updateStateCallback={handleUpdateStateCallback}
      parentCallback={(e) => {}}
      onSuccess={onSuccess}
      orgUuid={orgUuid}
    />
  );
}

function NewAddressFormWidget({
  id,
  state,
  dispatch,
  actions,
  isDirty,
  handleDiscard,
  handleParentCallback,
  fetchData,
  extraData,
  onSuccess = () => {},
  orgUuid,
}) {
  const { t } = useTranslation(["common", "transactions", "business"]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const signOut = useSignOut();
  const setDataUser = useSetDataUser();
  const matches = useMatchMedia(`(max-width: ${BREAKPOINT_MD}px)`);
  const [isPort, setIsPort] = useState(false);

  const handleSubmitWidget = async (e) => {
    e.preventDefault();
    handleParentCallback();
    setSubmitLoading(true);
    const data = buildNewOpportunityAddress(state, true);
    await handleCRUD({
      endpoint: `org/${orgUuid}/location`,
      method: "POST",
      data: {
        address: data,
      },
      successCallback: (e) => {
        onSuccess();
      },
      messageOk: t("action-ok"),
      signOut,
      setDataUser,
      setLoading: setSubmitLoading,
    });
  };

  return (
    <form onSubmit={handleSubmitWidget}>
      <Paper whiteMode style={{ alignItems: "stretch", padding: 15 }}>
        <Row gap={25}>
          <span>
            <Radio
              id="address_yes"
              name="isPort"
              text={t("address")}
              onChange={() => {
                setIsPort(false);
                handleOnChange(
                  "port",
                  dispatch
                )({
                  target: {
                    value: "",
                  },
                });
              }}
              checked={isPort === false}
              required
            />
          </span>
          <span>
            <Radio
              id="address_no"
              name="isPort"
              text={t("port")}
              onChange={() => {
                setIsPort(true);
                dispatch({
                  type: actions.UPDATE_INPUT,
                  payload: {
                    city: "",
                    street: "",
                    streetNumber: "",
                    postalCode: "",
                    countryCode: "",
                    port: "",
                    facilities: [],
                  },
                });
              }}
              checked={isPort === true}
              required
            />
          </span>
        </Row>
        {isPort && (
          <Row>
            <Label text={`${t("port")}`} noPadding>
              <CatalogSearch
                remote
                id="SelectedPort"
                inputProps={{
                  required: true,
                  placeholder: t("write-or-select"),
                  mode: "underline",
                }}
                initialValue={
                  state?.port && state?.port !== "empty"
                    ? state?.port?.name || state?.port
                    : undefined
                }
                catalogName={"ports"}
                avoidInitalLoad={true}
                onClickCallback={(option, open, setOpen, value, setValue) => {
                  handleOnChange(
                    "port",
                    dispatch
                  )({
                    target: {
                      value: option?.uuid,
                    },
                  });
                  setTimeout(() => {
                    setOpen(false);
                  }, 100);
                }}
                onChangeCallback={(e) => {
                  if (_.isEmpty(e.target.value)) {
                    handleOnChange(
                      "port",
                      dispatch
                    )({
                      target: {
                        value: undefined,
                      },
                    });
                  }
                }}
                mode="underline"
              />
            </Label>
          </Row>
        )}
        {!isPort && (
          <>
            <Row gap={30}>
              <Label text={t("street")} noPadding>
                <InputText
                  required
                  value={state?.street}
                  onChange={handleOnChange("street", dispatch)}
                  placeholder={t("write-here")}
                  mode="underline"
                />
              </Label>
              <Row gap={30} style={{ width: "100%" }}>
                <Label text={t("number")} noPadding>
                  <InputText
                    required
                    value={state?.streetNumber}
                    onChange={handleOnChange("streetNumber", dispatch)}
                    placeholder={t("write-here")}
                    mode="underline"
                  />
                </Label>
                <Label text={t("postal-code")} noPadding>
                  <InputText
                    required
                    value={state?.postalCode}
                    onChange={handleOnChange("postalCode", dispatch)}
                    placeholder={t("write-here")}
                    mode="underline"
                  />
                </Label>
              </Row>
            </Row>
            <Row gap={30}>
              <Label text={t("city")} noPadding>
                <InputText
                  required
                  value={state?.city}
                  onChange={handleOnChange("city", dispatch)}
                  placeholder={t("write-here")}
                  mode="underline"
                />
              </Label>
              <Label text={t("country")} noPadding>
                <InputCountries
                  required={true}
                  handleItemClick={handleCountryClick("countryCode", dispatch)}
                  value={state?.countryCode}
                  mode="underline"
                  onInnerValueChanged={(e) => {
                    if (_.isEmpty(e)) {
                      handleOnChange(
                        "countryCode",
                        dispatch
                      )({
                        target: {
                          value: "",
                        },
                      });
                    }
                  }}
                />
              </Label>
            </Row>
            <Row>
              <LoadPossibilities
                title={t(
                  state?.opportunityType === "sale"
                    ? "load_possibilities"
                    : "unload_possibilities"
                )}
                handleClick={(choice) => {
                  if (choice === LOAD_POSSIBILITY.none) {
                    handleOnChange(
                      "facilities",
                      dispatch
                    )({
                      target: {
                        value: [choice],
                      },
                    });
                    return;
                  }
                  const facilities = [...state?.facilities];
                  const choiceFound = facilities?.filter((f) => f === choice);
                  handleOnChange(
                    "facilities",
                    dispatch
                  )({
                    target: {
                      value:
                        choiceFound.length === 0
                          ? [...facilities, choice]
                          : facilities.filter((f) => f !== choice),
                    },
                  });
                }}
                showIconFn={(type) => state?.facilities?.includes(type)}
                typeFn={(type) => state?.facilities?.includes(type)}
              />
            </Row>
            <ValidateGroup
              value={state?.facilities?.length > 0 ? 1 : undefined}
            />
          </>
        )}
      </Paper>
      <Spacer height={15} />
      <Row justify="flex-end">
        <Button
          fullwidth={matches ? true : undefined}
          component="button"
          mode="info"
          text={t("save")}
          loading={submitLoading}
          style={
            submitLoading ? { paddingLeft: "4rem", paddingRight: "4rem" } : {}
          }
        />
      </Row>
    </form>
  );
}

export default NewAddressForm;
